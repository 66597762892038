<template>
	<div>

		<b-form-group v-if="options.length > 0">
			<v-select
        label="MealPlanDescriptionCustom"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        v-model="selected"
        :clearable="false"
        disabled
        >
        <!-- @input="setRateSelected(selected)" -->
      </v-select>
		</b-form-group>
    <b-alert show variant="danger"  class="mt-2" v-else>
      <div class="alert-body text-center"> 😣 <span>No se encontraron tarifas para esta habitación</span></div>
    </b-alert>
	</div>

</template>

<script>
import { mapState, mapMutations} from 'vuex'
import vSelect from 'vue-select'
import { toJson } from '@/helpers/helpers'

export default {
  created(){
    if (this.room.rates.some(rate => !!rate.ASIBenefit)){
      this.selected = this.room.rates.find(rate => rate?.ASIBenefit == this.bookingInfo?.benefit)
    }
  },
  props:{
    room: {
      type: Object,
      required: true
    }
  },
  components:{
    vSelect,
  },
  data(){
	  return {
      options: this.room.rates,
      selected: this.room.rates[0],
      politicas: [
        { value: '', name: 'Demo 1' },
        { value: '', name: 'Demo 2' },
      ],
    }
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["bookingInfo"]),
  },
  methods:{
    ...mapMutations('bookings',['setRateSelectedRoom']),
    ...mapMutations('bookings',['setRateSelectedRoom']),
    setRateSelected( rate ){
      // console.log( rate  )
      const payload = {
        detailSelected : toJson(rate),
        keyRoom: this.room.keyRoom,
        divisa: { currencyid: 1, code: "USD", value: "1.0000" }
      }
      if( rate ){
        this.setRateSelectedRoom(payload)
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>