<template>
  <div>
    <b-table
      hover
      :items="itemFields"
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      :busy.sync="isLoadingTableBenefit"
      @row-clicked="setDetail"
      class="mb-0"
      responsive
      show-empty
      empty-text="No hay transacciones para mostrar"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando Beneficios</strong>
        </div>
      </template>
      <template #cell(benefitname)="row">
        <span>{{ row.item.benefitname }}&nbsp;</span>
        <b-spinner
          small
          v-if="row.item.loadingBenefit"
          class="align-middle"
        ></b-spinner>
      </template>
      <template #cell(actions)="row">
        <span class="buttonInfo" v-if="canAddATransaction">
          <b-button
            v-if="bookingInfo.status != 3"
            size="sm"
            variant="primary"
            @click="openInfo(row.item)"
            class="mr-2"
            :disabled="checkOutItsOver"
          >
            <feather-icon icon="PlusIcon" size="16" />
          </b-button>
        </span>

        <span class="buttonInfo">
          <b-button
            v-if="row.item.isExtra == true && bookingInfo.status != 3 "
            size="sm"
            variant="light"
            @click="deleteTransaction(row.item)"
            class="mr-2"
            :disabled="checkOutItsOver"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </span>
        <ModalAddDetail
          v-if="bookingInfo.status != 3 && canAddATransaction"
          :detail="row.item"
          @receive-reload="reload"
          :selectedOwnerBookingEdit="bookingInfoList"
          :idContract="bookingInfo.contractid"
          @receive-transactions="receiveTransactions"
        />
        <!-- @refresh-table="contractTable" -->
        <ModalDeleteTransaction
          v-if="bookingInfo.status != 3"
          :detail="row.item"
          @receive-reload="reload"
          @cancelTransaction="cancelTransaction"
        />
        <ModalTransactionsDetail
          :activeBooking="bookingInfo.status != 3"
          :detail="row.item"
          :idContract="bookingInfo.contractid"
          :transactionsDetail="transactionsDetail"
          :loadingInfoDetail="loadingInfoDetail"
          :setDetailClass="setDetailClass"
          @delete-detail="setDeleteDetail"
          @row-class-cancel="rowClassCancel"
        />
      </template>
    </b-table>
  </div>
</template>

<script >
import { mapActions, mapMutations, mapState } from "vuex";
//data seeds
import { adultOptions, childrenOptions, ageChildrenOptions } from "@/data/data";
import {  showAlertMessage } from "@/helpers/helpers";

import ModalAddDetail from "@/modules/fivesClub/components/catalogs/ownersBookings/ModalAddDetail";
import ModalDeleteTransaction from "@/modules/fivesClub/components/catalogs/ownersBookings/ModalDeleteTransaction";
import ModalTransactionsDetail from "@/modules/fivesClub/components/catalogs/ownersBookings/ModalTransactionsDetail";

import flatPickr from "vue-flatpickr-component";
import { reservetype, occupanttype } from "@/data/fivesClub";
import * as moment from "moment";
import { acl } from "@/modules/auth/mixins/acl"
export default {

  mixins: [acl],

  props: {
    itemFields: {
      type: Array,
      required: true,
    },

    isLoadingTableBenefit: {
      type: Boolean,
      required: true,
    },
    guestInHouse: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    flatPickr,
    ModalAddDetail,
    ModalDeleteTransaction,
    ModalTransactionsDetail,

  },
  data() {
    return {
      searchFormWasModified: false,
      max: 255,
      authURL: process.env.VUE_APP_IMG_SRC_API,
      isUpdating: false,
      setDetailClass: 0,
      loadingInfo: false,
      loadingInfoDetail: false,
      showEdit: false,
      commetsBooking : false,
      peopleBooking : false,
      datesBooking : false,
      dateContractEnd:'',
      occupanttype,
      reservetype,
      newYearDate: new Date().getFullYear(),
      validDateContract:false,
      adultOptions,
      childrenOptions,
      ageChildrenOptions,
      selectedBenefit: [],
      isAlreadySelected: false,
      isLoadingFiles: false,
      transactionsData: [],
      transactionsDetail: [],
      tableColumns: [
        { key: "benefitname", label: "Nombre" },
        { key: "benefitqty", label: "Cantidad" },
        { key: "balance", label: "Disponible" },
        { key: "avaiable", label: "Usado" },
        { key: "actions", label: "Acciones" },
      ],
      fieldsDetail: [
        { key: "benefit", label: "Beneficio", class: "text-center" },
        { key: "invoice", label: "Orden" },
        { key: "quantity", label: "Cantidad" },
        { key: "userName", label: "Creado por" },
        { key: "statusTransactions", label: "estatus" },
        { key: "createdateFormart", label: "Fecha de creacion" },
        { key: "operationdate", label: "Fecha de operación" },
        { key: "notes", label: "Notas" },
        { key: "userCancelName", label: "Cancelado por" },
        { key: "cancelNotes", label: "Notas de Cancelación" },
        { key: "actions", label: "Acciones" },
      ],
      datenow: new Date(),
      year: "",
      benefitSelectedYear: new Date().getFullYear(),
    };
  },


  computed: {
    ...mapState("start", ["hotels"]),
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubCatalogs", ["bookingInfo", "owners","benefitId"]),
    ...mapState('mySales', ['bookingSelected']),
    canAddATransaction(){
      return !this.checkOutItsOver && ((this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_edit_add_transaction_only_in_house') && this.guestInHouse) ||
      this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_edit_add_transaction'))
    },

    checkOutItsOver(){
      let itsOver = false
      if(this.bookingInfo){
        const dateOut = moment(this.bookingInfo.dateout).add(23, 'hours')
        itsOver = moment().isAfter(dateOut)
      }
      return itsOver
    },
    checkInItsOver(){
      let itsOver = false
      if(this.bookingInfo) itsOver = moment().isAfter(this.bookingInfo.datein)
      return itsOver
    },

    bookingInfoList() {
      return this.bookingInfo;
    },

  },

  methods: {
    ...mapActions("mySales", ["fetchDataBooking"]),
    ...mapActions("fivesClubContracts", [
      "fetchTransactionsDetail",
      "fetchTransactions",
      "deleteDetailTransactions",
      "deleteTransactions",
      "getDocumentsTypeForContracts",
      "getFilesByContracts",
      "getFilesByBooking",
      "deleteFileInOwnerBooking",
    ]),
    ...mapActions("groups", ["bookingModifiedOwner"]),
    ...mapActions("fivesClubCatalogs", ["fetchContractsYear", "updateOwnerBooking", "getOwnerBookingBlack" ]),
    ...mapMutations("mySales", [
      "setBookingSelected",
      "setBookingToEdit",
      "setRoomsBookingSelected",
      "setDataSearchForm",
      "clearDataSearchForm",
    ]),
    ...mapMutations("bookings", ["setHotelSearch"]),
    ...mapMutations("fivesClubCatalogs", [
      "setDataSearchForm",
      "clearDataSearchForm",
      "setAgeChildsBookOwner",
      "setSpecificAgeChilBookOwner",
      "setAdultsBookOwner",
      "setNumberChildrenBookOwner",
      "setNumberAdultsBookOwner",
      "setBenefitSelected",
      "setBenefitSelectedEdit",
      "setcanChangeSatus"
    ]),

    // Transacciones
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.id === this.setDetailClass) return "table-success";
    },
    rowClassCancel(item, type) {
      if (!item || type !== "row") return;
      if (item.statusTransactions === "Cancelado") return "table-danger";
    },
    async setDetail(record, index, e) {
      record.loadingBenefit = true;
      this.loadingInfo = true;
      this.setDetailClass = 0;
      const infoDetail = {
        id: record.idTransaction,
      };
      const response = await this.fetchTransactionsDetail(infoDetail);
      if (response.length > 0) {
        this.setDetailClass = record.idTransaction;
        this.transactionsDetail = response;
        this.$root.$emit(
          "bv::show::modal",
          "modal-transaction-detail" + record.idTransaction
        );
        this.loadingInfo = false;
        record.loadingBenefit = false;
      } else {
        this.loadingInfo = false;
        record.loadingBenefit = false;
        return showAlertMessage(
          "Sin datos",
          "InfoIcon",
          "El beneficio no contiene transacciones",
          "warning",
          4000,
          "bottom-right"
        );
      }
    },

    receiveTransactions(transactions, trans) {
      this.transactionsDetail = transactions;
      this.transactionsData = trans;
      this.$root.$emit(
        "bv::show::modal",
        "modal-transaction-detail" + record.idTransaction
      );
    },
    async setDeleteDetail(data) {
      this.loadingInfoDetail = true;
      const payload = {
        idUser: this.user.idUser,
        amount: parseFloat(data.amount != "" ? data.amount : 0),
        quantity: parseFloat(data.quantity != "" ? data.quantity : 0),
        id: data.id,
        transaction: data.transaction,
        note: data.notesCancel,
      };

      const response = await this.deleteDetailTransactions(payload);

      if (response.status) {
        const info = {
          id: this.bookingInfo.contractid,
          year: this.datenow.getFullYear(),
        };
        const infoDetail = {
          id: data.transaction,
        };
        this.transactionsData = await this.fetchTransactions(info);
        this.transactionsDetail = await this.fetchTransactionsDetail(
          infoDetail
        );
        this.loadingInfoDetail = false;
        const msg = "La transaccion se ha cancelado";
        showAlertMessage(
          `Ok`,
          "InfoIcon",
          `🎉🎉 ${msg}`,
          "success",
          3000,
          "bottom-right"
        );
      } else {
        this.loadingInfoDetail = false;
        return showAlertMessage(
          "Actualización fallida",
          "InfoIcon",
          "No se ha podido borrar la transaccion. Intentelo más tarde",
          "danger",
          4000,
          "bottom-right"
        );
      }
      this.reload();
    },

    async reload() {
      this.$emit("reload");
    },
    async cancelTransaction(data) {
      this.loadingInfo = true;
      this.setDetailClass = 0;

      const payload = {
        idUser: this.user.idUser,
        id: this.bookingInfo.contractid,
        notes: data.internalNotes,
        transactionId: data.id,
        benefitID: data.benefitId,
      };
      const info = {
        id: this.bookingInfo.contractid,
        year: this.datenow.getFullYear(),
      };
      await this.deleteTransactions(payload);
      this.transactionsData = await this.fetchTransactions(info);
      this.loadingInfo = false;
    },
    openInfo(data) {
      if (data.idTransaction)
        this.$root.$emit("bv::show::modal", "modal-add" + data.idTransaction);
      else
        return showAlertMessage(
          "Sin transacciones",
          "InfoIcon",
          "El beneficio no contiene transacciones",
          "danger",
          4000,
          "bottom-right"
        );
    },
    deleteTransaction(data) {
      if (data.idTransaction)
        this.$root.$emit(
          "bv::show::modal",
          "modal-transactionDedelete" + data.idTransaction
        );
      else
        return showAlertMessage(
          "Sin transacciones",
          "InfoIcon",
          "El beneficio no contiene transacciones",
          "danger",
          4000,
          "bottom-right"
        );
    },
    // Documents
  },
};
</script>

<style scoped>

</style>