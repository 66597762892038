<template>
  <div class="container mt-3">
    <h4 class="text-center" v-if="bookingInfo.benefits ">Beneficios {{  yearEdit }} </h4>

    <table
      v-if="bookingInfo.benefits"
      class="table table-sm table-striped mt-2"
    >
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Cantidad</th>
          <th scope="col">Usado</th>
          <th scope="col">Disponible</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in bookingInfo.benefits" :key="index">
        <td scope="row">{{ item.benefitname }}</td>
        <td scope="row">{{ item.benefitqty }}</td>
        <td scope="row">{{ item.used }}</td>
        <td scope="row">{{ item.balance }}</td>

        </tr>
      </tbody>
    </table>
    <br>
    <div class="head-beneficios mb-1">
      <h4>Registro de Beneficios</h4>
      <b-button
        size="sm"
        variant="primary"
        v-b-tooltip.hover
        title="Descargue aquí el Saldo de Beneficios"
        :href="`${authURL}/FivesClub/benefitbalance/${bookingInfo.contractid}/${yearEdit}/`"
        target="_blank"
        rel="noopener noreferrer"
      > <feather-icon icon="FileTextIcon" /> Saldo de Beneficios
      </b-button>
    </div>

    <HandlerBenefits
      :isLoadingTableBenefit="isLoadingTableBenefit"
      :itemFields="itemFields"
      :guestInHouse="guestInHouse"
      @reload="reloadInBenefit"
    />
    <br>
    <div class="btn-docs-up">
      <b-button
        variant="primary"
        v-b-tooltip.hover
        title="Autorización de Transacciones"
        :href="authURL + '/FivesClub/authorizationtransactions/' + bookingInfo.id"
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-primary"
        v-if="can('fivesclub_owner_booking_show_booking_reservation_list_action_button_edit_show_transaction')"
        ><b-icon-file-earmark-ruled-fill />
      </b-button>
    </div>
  </div>
</template>

<script>
import HandlerBenefits from "@/modules/fivesClub/components/catalogs/ownersBookings/HandlerBenefits"
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  components: {
    HandlerBenefits,
  },
  props: {
    bookingInfo: {
      type: Object,
      required: true,
    },
    isLoadingTableBenefit: {
      type: Boolean,
      required: true,
    },
    guestInHouse: {
      type: Boolean,
      required: true,
    },
    yearEdit: {
      type: [String, Number],
      required: true,
    },

    itemFields: {
      type: Array,
      required: true,
    },
  },
  data() {
		return {
		
			authURL: process.env.VUE_APP_IMG_SRC_API,
		

		}
	},
  methods: {
    reloadInBenefit(){
      this.$emit('reload')
    }
  }
}
</script>

<style>

</style>