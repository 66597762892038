<template>
    <div class="">
      <validation-observer
        v-if="bookingInfo.benefits.length > 0"
        ref="bookingInfobooking"
        tag="form"
      >
        <b-form>
          <b-row>
            <b-col
              v-if="validBenefit == true"
              cols="12"
              class="table-benefit mt-2"
            >
              <b-alert variant="danger" show>
                <div class="alert-body text-center">
                  <span
                    ><strong>¡Sin registros!</strong> No se encontraron beneficios del {{ benefitSelectedYear }}</span
                  >
                </div>
              </b-alert>
            </b-col>
            <b-col
              v-else-if="isLoadingBenefits == false && listBenefit != 0"
              cols="12"
              class="table-benefit mt-2"
            >
              <b-row
                v-if="
                  bookingInfo.isEdit != 'true' &&
                  bookingInfo.contractList.membershipcode != 'CLASSIC'
                "
              >
                <b-col md="2">
                  <label>Seleccione un año</label>
                  <b-form-select
                    v-model="benefitSelectedYear"
                    :options="validYears"
                    :disabled="validDateContract"
                    size="sm"
                  />
                </b-col>
                <b-col md="1">
                  <label class="transparent">...</label>
                  <b-button
                    class="btn mr-1"
                    size="sm"
                    variant="primary"
                    @click="searchBenefitsByYear"
                  >
                    Buscar
                  </b-button>
                </b-col>
              </b-row>
              <b-col cols="10">
              <div class="display-6 mb-1">
                  <div class=" mt-1" v-if=" bookingInfo.isEdit != 'true'">
                    <b-badge variant="warning">
                      <feather-icon icon="AlertCircleIcon" class="mr-25" />
                      <span>
                        Membresía:{{ bookingInfo.contractList.membershipcode }}
                      </span>
                    </b-badge>
                  </div>
              </div>
            </b-col>
            </b-col>
          </b-row>                        
          <b-row v-if="listBenefit == 0">
            <b-col md="12">
              <b-alert show variant="danger" class="mt-2">
                <div class="alert-body text-center">
                  <span>No cuenta con noches para realizar una reserva.</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
  
          <!-- v-if="bookingInfo.status != 3" -->
          <SearchRoom
            :isUpdating="isUpdating"
            :bookingInfo="bookingInfo"
            :checkOutItsOver="checkOutItsOver"
            :checkInItsOver="checkInItsOver"
            :filteredHotels="filteredHotels"
            :adultOptions="adultOptions"
            :occupanttype="occupanttype"
            :listBenefit="listBenefit"
            :isLoadingRooms="isLoadingRooms"
            :btnSearchOrUpdate="btnSearchOrUpdate"
            :dateCheckConfig="dateCheckConfig"
            :childrenOptions="childrenOptions"
            :ageChildrenOptions="ageChildrenOptions"
            :isAlreadySelected="isAlreadySelected"
            :bookingEditable="bookingEditable"
            @set-nights="setNights"
            @set-hotel="setHotel"
            @change-commets="isChangeCommets"
            @change-number-adults="changeNumberAdults"
            @change-number-children="changeNumberChildren"
            @change-occupation="changeOccupation"
            @delete-age-adults="deleteAgeAdults"
            @delete-age-child="deleteAgeChild"
            @set-age-ninio="setAgeNinio"
            @reset-form="resetForm"
            @get-bookings="getBookings"
            @booking-modify="bookingModify"
            @tooltip-title="tooltipTitle"
            v-if="bookingInfo.status != 3 && !noValidContract"
          />
  
          <canceledBooking :bookingInfo="bookingInfo" v-else/>
        </b-form>
      </validation-observer> 
    </div>
  </template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { adultOptions, childrenOptions, ageChildrenOptions } from "@/data/data";
import { toJson, showAlertMessage, stringAleatorio, sortalphabetically, currentDate } from "@/helpers/helpers"
import {  makeParamsOwners, setCanChangeEditBooking, makeParamsFilterOwnersBookings } from "@/helpers/fivesClubHelper"
import SearchRoom from "@/modules/fivesClub/components/catalogs/ownersBookings/SearchRoom";
import canceledBooking from "@/modules/fivesClub/components/catalogs/ownersBookings/canceledBooking";

import flatPickr from "vue-flatpickr-component";
import { reservetype, occupanttype } from "@/data/fivesClub";
import * as moment from "moment";
import { acl } from "@/modules/auth/mixins/acl"
	
export default {
	mixins: [acl],
	created(){
		this.manageBookingYear()
	},
	mounted() {
		if (this.year === "") {
			const currentYear = new Date().getFullYear();
			this.year = currentYear;
		}
	},
	props: {
		itemFields: {
			type: Array,
			required: true,
		},
		fileTypes: {
			type: Array,
			required: true,
		},
		documents: {
			type: Array,
			required: true,
		},
		isLoadingRooms: {
			type: Boolean,
			required: true,
		},
		isLoadingBenefits: {
			type: Boolean,
			required: true,
		},
		isLoadingTableBenefit: {
			type: Boolean,
			required: true,
		},
		showBookingsFilters: {
			type: Boolean,
			required: true,
		},
		listBenefit: {
			type: Number,
			required: true,
		},
		validBenefit: {
			type: Boolean,
			required: true,
		},
		checkOutItsOver: {
			type: Boolean,
			required: true,
		},
		checkInItsOver: {
			type: Boolean,
			required: true,
		},
		bookingEditable: {
			type: Object,
			required: true,
		},
		noValidContract: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		flatPickr,         
		SearchRoom,
		canceledBooking,
	},
	data() {
		return {
			isUpdatingBooking: false,
			searchFormWasModified: false,
			max: 255,
	
			authURL: process.env.VUE_APP_IMG_SRC_API,
			isUpdating: false,      
						
			showEdit: false,
			commetsBooking : false,
			peopleBooking : false,
			datesBooking : false,
			occupanttype,
			reservetype,
			newYearDate: new Date().getFullYear(),
			validDateContract:false,
			adultOptions,
			childrenOptions,
			ageChildrenOptions,      
			isAlreadySelected: false,
			datenow: new Date(),
			year: "",
			benefitSelectedYear: new Date().getFullYear(),
		};
	},

	watch: {
		bookingInfo: {
			deep: true,
			handler() {
				this.manageBookingYear()
			},
		},	
		// Para ver si los valores del formulario cambian de valor, limpiar listado de rooms cuando eso ocurra
		searchBookingInfo(){
			if(this.bookingSelected){
				this.setRoomsBookingSelected([])
			}
		}
	},
	computed: {
		...mapState("start", ["hotels"]),
		...mapState("auth", ["user"]),
		...mapState("fivesClubCatalogs", ["bookingInfo", "owners","benefitId", 'ownerBookingFilters']),
		...mapState('mySales', ['bookingSelected']),
		btnSearchOrUpdate(){
			const ishistory = this.bookingInfo?.ishistory
			const showSearch = !ishistory ? this.searchFormWasModified : false
			const showUpdate = !ishistory ? !this.searchFormWasModified : true
	
			return { showSearch, showUpdate }
		},
		yearEdit(){
			return new Date().getFullYear()
		},
		filteredHotels() {
			return sortalphabetically(
				this.hotels.filter((hotel) => hotel.dingusCode != null)
			);
		},
		bookingInfoList() {
			return this.bookingInfo;
		},
		tooltipTitle() {
			return this.isLoadingRooms
			? "Cargando habitaciones"
			: this.bookingInfo.rsv == ""
			? ""
			: this.isAlreadySelected == false
			? "Seleccione un beneficio para continuar"
			: !this.isAlreadySelected
			? "Seleccione un beneficio para continuar"
			: "Realizar Búsqueda";
		},
		validYears() {
			return [this.datenow.getFullYear(), this.datenow.getFullYear() + 1];
		},
		dateCheckConfig() {
			const dateEndContract= moment(!!this.bookingInfo.contractList.endDate ? this.bookingInfo.contractList.endDate : this.bookingInfo.contractList.dateEnd)
			const yearEndContract= dateEndContract.year()
			// Si la fecha de checkin ya pasó, tomo el dateIn como minimo, sino tomo la fecha actual
			const dateMinBooking = (this.checkInItsOver) ? this.bookingInfo.datein : currentDate()

			let date = new Date()

			if (yearEndContract != date.getFullYear()) this.validDateContract= false
			else this.validDateContract= true

			if (yearEndContract != this.newYearDate) date = `${this.newYearDate + 1}-01-31`
			else date=dateEndContract.format('YYYY-MM-DD')

			let config = {
				mode: "range",
				// minDate: "today",
				minDate: !this.checkOutItsOver ? dateMinBooking : "today",
				maxDate: date,
			}

			if (this.newYearDate > this.datenow.getFullYear()) config.minDate = !this.checkOutItsOver ? dateMinBooking : this.newYearDate

			return config
		},
		searchBookingInfo(){
			const {rangeDate, dateIn, dateOut, infoAdults, ninos, resortId, occupanttype  } = this.bookingInfo
			return { rangeDate, dateIn, dateOut, infoAdults, ninos, resortId, occupanttype }
		}
	},

	methods: {
		...mapActions("mySales", ["fetchDataBooking"]),
		...mapActions("fivesClubContracts", [
			"fetchTransactionsDetail",
			"fetchTransactions",
			"deleteDetailTransactions",
			"deleteTransactions",
			"getDocumentsTypeForContracts",
			"getFilesByContracts",
			"getFilesByBooking",
			"deleteFileInOwnerBooking",
		]),
		...mapActions("groups", ["bookingModifiedOwner"]),
		...mapActions("fivesClubCatalogs", ["fetchContractsYear", "updateOwnerBooking", "getOwnerBookingBlack", "fetchOwnerBookingFilter" ]),
		...mapMutations("mySales", [
			"setBookingSelected",
			"setBookingToEdit",
			"setRoomsBookingSelected",
			"setDataSearchForm",
			"clearDataSearchForm",
		]),
		...mapMutations("bookings", ["setHotelSearch"]),
		...mapMutations("fivesClubCatalogs", [
			"setDataSearchForm",
			"clearDataSearchForm",
			"setAgeChildsBookOwner",
			"setSpecificAgeChilBookOwner",
			"setAdultsBookOwner",
			"setNumberChildrenBookOwner",
			"setNumberAdultsBookOwner",
			"setBenefitSelected",
			"setBenefitSelectedEdit",
			"setcanChangeSatus",
			"setOwnersBookings",
			"setIsLoadingOwnersBookings",
		]),

		// Transacciones      
		async reload() {
			this.$emit("reload");
		},
		resetForm() {
			showAlertMessage( `Datos limpiados`, "BellIcon", `🎉🎉 Datos de formulario limpiados`, "success", 3000, "bottom-right" )
			this.setBookingSelected(null);
			this.clearDataSearchForm();
		},
		setHotel(){
			this.searchFormWasModified = true
		},
		setNights(rangeDate) {
			if (this.bookingInfo.rangeDate.includes("to")) {
				const splitDate = rangeDate.split(" to ");
				this.bookingInfo.dateIn = splitDate[0] || moment(new Date()).format("YYYY-MM-DD");
				this.bookingInfo.dateOut = splitDate[1] || moment(new Date()).add(1, "days").format("YYYY-MM-DD")
				const inicio = moment(splitDate[0]);
				const fin = moment(splitDate[1]);
				this.bookingInfo.nights = splitDate[0] && splitDate[1] ? fin.diff(inicio, "days") : 0;
			} else {
				this.bookingInfo.nights = 0
			}
	
				const ishistory = this.bookingInfo.ishistory
			if (
				this.bookingInfo.datein == this.bookingInfo.dateIn &&
				this.bookingInfo.dateout == this.bookingInfo.dateOut &&
				this.bookingInfo.isEdit == "true"
				){
				this.showEdit = true
			}
			else if (ishistory == true) {
				this.showEdit = true
				this.datesBooking=true
				this.validsWasModified()
			} else {
				this.datesBooking=true
				this.validsWasModified()
				this.showEdit = false;
			}
		},
	
		changeNumberChildren(numberChildren) {
			this.peopleBooking=true
			this.validsWasModified()
			const { ninos } = toJson(this.bookingInfo);
			let ageNinos = [];
			const diferencia = numberChildren - ninos.length;
	
			if (diferencia == 0) ageNinos = ninos;
	
			if (diferencia > 0) {
				for (let i = 0; i < diferencia; i++) {
					ageNinos.push({ edad: 1, name: "", key: stringAleatorio() });
				}
				ageNinos.unshift(...ninos); // recupero los datos originales y los añado a los nuevos
			}
			if (diferencia < 0) {
				for (let i = 0; i < numberChildren; i++) {
					ageNinos.push({ edad: 1, name: "", key: stringAleatorio() });
				}
			}
			this.setAgeChildsBookOwner(ageNinos);		
		},
		setAgeNinio(child) {
			const { edad, key, name } = child;
			this.setSpecificAgeChilSearchForm({ edad, key, name });
		},
		deleteAgeChild(key) {
			const ageNinios = this.bookingInfo.ninos.filter( (nino) => nino.key != key )
			this.setAgeChildsBookOwner(ageNinios);
			this.setNumberChildrenBookOwner(this.bookingInfo.numberChildren - 1);
		},
		async changeNumberAdults(adults) {
			this.peopleBooking=true
			this.validsWasModified()

			const adultsOwners = []

			if (!this.bookingInfo.namesbeneficiaries.find((guest) => guest.ismain == true)) this.bookingInfo.namesbeneficiaries[0].ismain = true

			const originalAdults = this.bookingInfo.namesbeneficiaries.filter(guests => guests.adult == true)
			const newAdults = this.bookingInfo.infoAdults.filter(guests => guests.adult == true)

			for (let i = 0; i < adults.adults; i++) {
				const key = stringAleatorio();
				let adultDetail = {
					edad: "",
					email: i != 0 ? "NA" : "",
					isadult: true,
					ismain: false,
					key,
					name: "",
					lastname: "NA",
					phone: "5555555555",
				}

				if (i === 0) adultDetail = {...originalAdults.find((guest) => guest.ismain == true), key }
				if (Boolean(newAdults[i])) adultDetail = { ...newAdults[i], key }
				else if (Boolean(originalAdults[i])) adultDetail = { ...originalAdults[i], key }

				adultsOwners.push(adultDetail);
			}

			this.setAdultsBookOwner(adultsOwners);
		},
		async changeOccupation() {
			this.peopleBooking=true
			this.validsWasModified()
			this.changeNumberAdults(this.bookingInfo);
		},
		deleteAgeAdults(key) {
			const ageAdult = this.bookingInfo.infoAdults.filter( (adult) => adult.key != key )
			this.setAdultsBookOwner(ageAdult);
			this.setNumberAdultsBookOwner(this.bookingInfo.adults - 1);
		},
		getBookings(bookingInfo) {      
			const el = document.getElementById("footer");
			const { rangeDate, dateIn, dateOut, nights, rsv, id } = bookingInfo;
			el.scrollIntoView({ behavior: "smooth" });
			this.$refs.bookingInfobooking.validate().then((success) => {
				if (success) {
					if (
						rangeDate != 0 &&
						dateIn != "" &&
						dateOut != "" &&
						nights > 0 &&
						rsv != ""
					) {
						bookingInfo.idBooking = id
						this.$emit("search-rooms", bookingInfo)
					} else {
						showAlertMessage(`Fecha: Check in - Check out`, "InfoIcon", `😰😰 Falta que se indique valor para Check in - Check out`, "danger", 3000, "bottom-right" )
					}
				}
			});
		},  
		
		async bookingModify(ownerBooking) {
			
			this.setcanChangeSatus(this.searchFormWasModified)
			const datesWasChanged = this.bookingInfo.datein != this.bookingInfo.dateIn || this.bookingInfo.dateout != this.bookingInfo.dateOut
	
			let messageAlert  = ''
			ownerBooking.needAuthorization = ownerBooking.needAuthorization
			ownerBooking.reasonAuthorization = ""

			if(datesWasChanged){
				//si las noches de su reserva supera a las noches (disponibles a usar) del beneficio seleccionado, no pasa de aqui, se valida desde back
				const { isInvalidNights, needAuthorization, message, info, booksNear } = await this.validateNights(ownerBooking)
	
				ownerBooking.needAuthorization = needAuthorization
				ownerBooking.reasonAuthorization = "Name: "+ info.name + ", Total: " + info.total
	
				messageAlert = message
				if( isInvalidNights ){
					this.bookingInfo.dateOut = this.bookingInfo.dateout
					this.bookingInfo.dateIn  = this.bookingInfo.datein
					this.bookingInfo.rangeDate = `${this.bookingInfo.datein } to ${this.bookingInfo.dateout}`
	
					return this.$swal.fire({
						icon: 'error',
						title: 'Noches insuficientes',
						text: 'La reservación correspondiente no tiene noches suficientes'
					})
				}
				if( booksNear ){
					this.bookingInfo.dateOut = this.bookingInfo.dateout
					this.bookingInfo.dateIn  = this.bookingInfo.datein
					this.bookingInfo.rangeDate = `${this.bookingInfo.datein } to ${this.bookingInfo.dateout}`
	
					const manyBooks = valid?.booksNear?.data?.length > 1
					await this.nearDatesModal({
						icon: 'AlertCircleIcon',
						title: manyBooks ? 'Se han encontrado varias reservas cercanas' : 'Una reserva cercana encontrada',
						message: `${ manyBooks ? 'Existen reservaciones' : 'Hay una reserva'} cerca de los dias seleccionados. Elija otras fechas con una diferencia de ${valid?.booksNear?.lapse} días a ${ manyBooks ? 'estas':'esta'}`,
						data: valid?.booksNear?.data,
					})
					return
				}
			}
	
			const isConfirmed = await this.confirmUpdate(messageAlert)
	
			if ( !isConfirmed ) {
				this.bookingInfo.dateOut = this.bookingInfo.dateout
				this.bookingInfo.dateIn  = this.bookingInfo.datein
				this.bookingInfo.rangeDate = `${this.bookingInfo.datein } to ${this.bookingInfo.dateout}`
				return false;
			}
			
			this.isUpdating = true;
			const payloadNames = {
				children: ownerBooking.ninos,
				infoAdults: ownerBooking.infoAdults,
			};
			const list = makeParamsOwners(payloadNames);

			ownerBooking.idUser = this.user.idUser;
			ownerBooking.infoBeneficiaries = list
			ownerBooking.canChangeSatus=this.bookingInfo.canChangeSatus
			ownerBooking.children = ownerBooking.numberChildren
							
			const idOwnerBooking = await this.updateOwnerBooking(ownerBooking)
			const { id, adults, numberChildren, nights, invitedAdults, ninos } = ownerBooking

			const payload = {
				id: id,
				idBooking: id,
				adults: adults,
				children: numberChildren,
				numberChildren,
				email: invitedAdults.length ? invitedAdults[0].email : '',
				name:  invitedAdults.length ? invitedAdults[0].name : '',
				lastname:  invitedAdults.length ? invitedAdults[0].lastname : '',
				phone:  invitedAdults.length ? invitedAdults[0].phone : '',
				nights: nights,
				ninos: ninos,
				isEditBooking: true,
			}
			
			// console.log('bookingModifiedOwner searchForm')
			if( this.bookingInfo.isEdit != "false" ){
				await this.bookingModifiedOwner(payload) // actualizo
				//actualizo data de la reserva
				this.$emit('load-info')
			}

			this.isUpdating = false

			// actualizar listado de bookings
			this.setIsLoadingOwnersBookings(true)
			// this.setOwnersBookings([])
			const payloadGetRsvs = makeParamsFilterOwnersBookings(this.ownerBookingFilters)
			const ownersBookings = await this.fetchOwnerBookingFilter(payloadGetRsvs)
			this.setOwnersBookings(ownersBookings)
			this.setIsLoadingOwnersBookings(false)

		},
		async validateNights(dataBooking){
			const { nights, dateIn, dateOut, benefits, contract, id, invitedAdults, contractList, owner} = dataBooking
			const payload = {
				benefits: benefits.find(b => b.selected).benefit, //es el id del beneficio, obtengo el id del beneficio que esté como selected
				contract,// id del contracto igual viene en contractid
				dateIn,
				dateOut,
				id,
				isEdit: true,
				nights,
				owner: owner || 0, //obtengo el id del owner
			}
			/*{
				"status": true, "data": "This reservation cannot be processed because it has a current cutoff.",
				"blackout": 0, "blackoutValid": false, "additional": false, "nights": 11, "cutoff": true,
				"info": { "name": "Cutoff | Baja post verano 2022", "total": 30 }, "message": "Valid data."
			}*/
			const { nights:noches, blackout, status, cutoff, blackoutValid, additional, info, nightsTA, booksNear } = await this.getOwnerBookingBlack(payload) //desestructuro respuesta
			/*
				isInvalidNights sí  noches es cero o blackoout -1 - > isInvalidNights false, pasa el proceso de la rsv
				needAuthorization si status o cuttoff es true -> needAuthorization en true no pasa la rsv, hasta aceptar la authorization
			*/
			let message = noches == 0 ? 'No cuenta con noches suficientes para esta reserva. ' : ''
			message += additional == 2 ? 'La reserva se tomara como adicional.' : ''
			message += blackoutValid ? 'La reserva tiene un blackout.' : ''
			message += cutoff ? 'La reserva tiene un cutoff.' : ''
			message += nightsTA ? 'No cuenta con las noches de temporada alta suficientes para esta reserva.' : ''
			message += booksNear ? 'Hay reservas cerca de las fechas seleccionadas.' : ''
	
			return{
				isInvalidNights: noches == 0 || blackout == -1,
				needAuthorization: status || cutoff,
				existAReservation: contractList.membershipcode === 'CLASSIC' && additional,
				nights: noches,// noches válidas, como nightsValid
				blackout, status, cutoff,
				authorization: status, //de status
				blackoutValid,
				additional,
				message,
				info,
				nightsTA,
				booksNear
			}
		},

		async confirmUpdate( message = '' ) {
			const { isConfirmed } = await this.$swal.fire({
				title: "Actualizar",
				// text: `Quiere actualizar la reservación? <b>${message} <b>`,
				html: `Quiere actualizar la reservación? <br><b>${message} <b>`,
				icon: "warning",
				showDenyButton: true,
				confirmButtonText: "Sí",
				denyButtonText: "No",
				reverseButtons: true,
				customClass: {
					actions: 'my-actions',
					confirmButton: 'mr-1 order-1',
					denyButton: 'order-2',
				}
			})
			return isConfirmed
		},
		async searchBenefitsByYear() {
			this.isLoadinsHousingOwners = true;
			const payload = {
				id: this.bookingInfo.contract,
				year: this.benefitSelectedYear,
			};
	
			this.$emit("set-benefitsyear", payload);
			this.bookingInfo.year = this.benefitSelectedYear;
			this.newYearDate = this.benefitSelectedYear;
			this.isLoadinsHousingOwners = false;
		},
		calculateDateBenefit(dateEnd){
			let  dateContract= new Date(dateEnd)
			dateContract=this.sumarDias(dateContract, 0)
			const yearContract= dateContract.getFullYear()
			return yearContract
		},
		sumarDias(fecha, dias){
			fecha.setDate(fecha.getDate() + dias);
			return fecha
		},
		isChangeCommets() {
			this.commetsBooking = true
			this.validsWasModified()
		},
		validsWasModified() {
			const listCommets={
				name:"commetsBooking",
				isChange:this.commetsBooking
			}
			const listDates ={
				name:"datesBooking",
				isChange:this.datesBooking
			}
			const listPeople = {
				name:"peopleBooking",
				isChange:this.peopleBooking
			}
	
			const  validCommets  = setCanChangeEditBooking(listCommets)
			const  validDates    = setCanChangeEditBooking(listDates)
			const  validPeople   = setCanChangeEditBooking(listPeople)
	
			if (validCommets==true || validDates==true || validPeople==true) this.searchFormWasModified=true
			else this.searchFormWasModified=false
		},
		manageBookingYear(){
			if (this.bookingInfo?.benefits?.length > 0) {
				const ediYear= this.calculateDateBenefit(this.bookingInfo.dateout)
				if (this.benefitId) this.setBenefitSelectedEdit(this.benefitId)
				this.newYearDate=this.bookingInfo.year ||  ediYear
				this.benefitSelectedYear =this.bookingInfo.year ||  ediYear

			}
			if (this.bookingInfo.status == 2) this.showEdit = true
			else this.showEdit = false
		},
		async nearDatesModal(info){
			const {icon, title, message, data} = info
				const h = this.$createElement
	
			// const titleVNode = h('label', { domProps: { innerHTML: title } })
	
			const datafields = [
				{key: 'code', label: 'Folio reserva'},
				{key: 'datein', label: 'Check in'},
				{key: 'dateout', label: 'Check out'}
			]
			
			const messageVNode = h('div', { class: ['foobar'] }, [
				// h('feather-icon', { props: {icon} }),
				h('p', { class: ['text-center mb-1'] }, [
					`${message}`,
					h('br'),
					h('strong', [`Sus fechas: ${this.bookingInfo.dateIn} ➡️ ${this.bookingInfo.dateOut}`]),
				]),
				h('b-table', {
					props: {
						items: data,
						fields: datafields,
						small: true,
						responsive: true,
					}
				})
			])
	
			const modal = await this.$bvModal.msgBoxOk([messageVNode],{
				title,
				size: 'sm',
				centered: true,
				hideHeaderClose: false,
				})
			
			return modal
		},	
	},
};
</script>
  
<style lang="scss"scoped>
	@import "@core/scss/vue/libs/vue-select.scss";
	@import "@core/scss/vue/libs/vue-flatpicker.scss";
	
	button.swal2-confirm.swal2-styled {
		margin: 0 13px 0 0px;
	}
	.transparent {
		color: transparent;
	}
	
	.display-6 {
		font-size: 13px;
	}
	
      .my-actions { margin: 0 auto; margin-top: 1rem; }
      .order-1 { order: 1; }
  </style>
  